import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import logo from '../pic/todo.jpeg';
import Cookies from 'js-cookie';
import axios from 'axios';

const Header = ({ setFilter }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [currUser, setCurrUser] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = Cookies.get('token');
        const userId = Cookies.get('userid');
        const response = await axios.get(`https://check-backend.xyz/api/user/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });
        const data = response.data;
        let name = '';
        if (data.length > 14) {
          name = data.substring(0, 9) + '...';
        } else { name = data}
        setCurrUser(name);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    Cookies.remove('Authorization');
    navigate('/login');
  };

  return (
    <header className="header">
      <div className="logo">
        <div className="logo-container">
          <Link to="/" className="logo">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
      </div>
      <h1 className="navig-link">Check-Mark</h1>
      <div className={`nav-container ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
      <div className="profile">
        <div className="profile-container">
          <p className="username">{currUser || "defaultUser"}</p>
          <p className="logout" onClick={handleLogout}>Log Out</p>
        </div>
      </div>
      </div>
    </header>
  );
};

export default Header;
