import React, { useState, useEffect } from 'react';
import './Home.css';
import Header from './Header';
import axios from 'axios';
import Cookies from 'js-cookie';
import chroma from 'chroma-js';
import { FaEdit, FaInfoCircle, FaTrash, FaLink } from 'react-icons/fa';

const Home = () => {
  const [applications, setApplications] = useState([]);
  const [filter, setFilter] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [newApplication, setNewApplication] = useState({
    name: '',
    description: '',
    status: 'pending',
    date: '',
    link: '',
    location: '',
    userId: Cookies.get('userid')
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    description: '',
    date: '',
    link: '',
    location: ''
  });

  const fetchApplications = () => {
    axios.get(`https://check-backend.xyz/api/applications/${Cookies.get('userid')}`, {
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    })
      .then(response => {
        const fetchedApplications = response.data || [];
        const sortedApplications = fetchedApplications.sort((a, b) => new Date(a.date) - new Date(b.date));
        setApplications(sortedApplications);
      })
      .catch(error => {
        console.error('Error fetching applications:', error);
      });
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  const filterApplications = () => {
    return applications.filter(application => {
      if (filter === 'applied') {
        return application.status === 'applied';
      } else if (filter === 'interview') {
        return application.status === 'interview';
      } else if (filter === 'offer') {
        return application.status === 'offer';
      }
      return true;
    });
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Format: YYYY-MM-DD
  };
  
  console.log(selectedApplication)
  const handleAddApplicationClick = () => {
    setShowModal(true);
  };

  const handleOpenEditModal = (application) => {
    setSelectedApplication(application);
    setNewApplication({
      name: application.name,
      description: application.description,
      status: application.status,
      date: application.date,
      link: application.link,
      location: application.location,
      userId: Cookies.get('userid') 
    });
    setShowEditModal(true);
  }
  const handleOpenDescriptionModal = (application) => {
    setSelectedApplication(application);
    setShowDescriptionModal(true);
  }
  const handleOpenDeleteModal = (application) => {
    setSelectedApplication(application);
    setShowDeleteModal(true);
  }

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleCloseDescriptionModal = () => setShowDescriptionModal(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setNewApplication({
      name: '',
      description: '',
      status: 'pending',
      date: '',
      link: '',
      location: '',
      userId: Cookies.get('userid')
    });
    setFormErrors({
      name: '',
      description: '',
      date: '',
      link: '',
      location: ''
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewApplication({
      ...newApplication,
      [name]: value
    });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      name: '',
      description: '',
      date: '',
      link: '',
      location: ''
    };

    if (!newApplication.name.trim()) {
      errors.name = 'Company name is required';
      isValid = false;
    }
    if (!newApplication.description.trim()) {
      errors.description = 'Description is required';
      isValid = false;
    }
    if (!newApplication.date) {
      errors.date = 'Date is required';
      isValid = false;
    }
    if (!newApplication.link.trim()) {
      errors.link = 'Job posting link is required';
      isValid = false;
    }
    if (!newApplication.location.trim()) {
      errors.location = 'Location is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleCopy = (application) => {
    navigator.clipboard.writeText(application.link)
      .then(() => {
        alert('Link copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy the link: ', err);
      });
  };

  const handleDelete = () => {
    axios.delete(`https://check-backend.xyz/api/applications/${selectedApplication.id}`, {
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    })
      .then(response => {
        fetchApplications();
        handleCloseDeleteModal();
      })
      .catch(error => {
        console.error('Error fetching applications:', error);
      });
  };

  const handleSort = (field, order) => {
    console.log(order);
    const sortFunctions = {
        company: (a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) return order === 'asc' ? -1 : 1;
            if (nameA > nameB) return order === 'asc' ? 1 : -1;
            return 0;
        },
        status: (a, b) => {
            const statusOrder = ['offer', '3+ round', '2nd round', '1st round', 'pending', 'applied', 'declined'];
            return (statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)) * (order === 'asc' ? 1 : -1);
        },
        location: (a, b) => {
            const remoteA = a.location.toLowerCase().includes('remote');
            const remoteB = b.location.toLowerCase().includes('remote');

            if (order === 'asc') {
                if (remoteA && !remoteB) return -1;
                if (!remoteA && remoteB) return 1;
                if (a.location.toLowerCase() < b.location.toLowerCase()) return -1;
                if (a.location.toLowerCase() > b.location.toLowerCase()) return 1;
                return 0;
            } else {
                if (remoteA && !remoteB) return 1;
                if (!remoteA && remoteB) return -1;
                if (a.location.toLowerCase() < b.location.toLowerCase()) return 1;
                if (a.location.toLowerCase() > b.location.toLowerCase()) return -1;
                return 0;
            }
        },
        date: (a, b) => {
            return (new Date(a.date) - new Date(b.date)) * (order === 'asc' ? 1 : -1);
        },
    };

    const sortedApplications = [...applications].sort(sortFunctions[field]);
    setApplications(sortedApplications);
};


  
  const handleEditSubmit = () => {
    console.log(newApplication)
    if (!validateForm()) return;
    axios.put(`https://check-backend.xyz/api/applications/${selectedApplication.id}`, newApplication,{
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    })
      .then(response => {
        fetchApplications();
        handleCloseModal();
      })
      .catch(error => {
        console.error('Error adding application:', error);
      });
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    axios.post('https://check-backend.xyz/api/applications', newApplication, {
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    })
      .then(response => {
        fetchApplications();
        handleCloseModal();
      })
      .catch(error => {
        console.error('Error adding application:', error);
      });
  };

  const getStatusDotColor = (status) => {
    switch (status) {
      case 'pending':
        return '#1F77B4';
      case 'declined':
        return '#E74C3C';
      case '1st round':
      case '2nd round':
      case '3+ round':
        return '#2ECC71';
      case 'offer':
        return '#F1C40F';
      default:
        return '#7F8C8D';
    }
  };

  return (
    <div>
      <Header />
      <div className="application-table">
        <div className="table-header">
        <div className="table-cell">
        Company
        <span className="sort-icons">
          <span className="sort-icon" onClick={() => handleSort('company', 'asc')}>↑</span>
          <span className="sort-icon" onClick={() => handleSort('company', 'desc')}>↓</span>
        </span>
      </div>
      <div className="table-cell">
        Status
        <span className="sort-icons">
          <span className="sort-icon" onClick={() => handleSort('status', 'asc')}>↑</span>
          <span className="sort-icon" onClick={() => handleSort('status', 'desc')}>↓</span>
        </span>
      </div>
      <div className="table-cell">
        Date
        <span className="sort-icons">
          <span className="sort-icon" onClick={() => handleSort('date', 'asc')}>↑</span>
          <span className="sort-icon" onClick={() => handleSort('date', 'desc')}>↓</span>
        </span>
      </div>
      <div className="table-cell">
        Location
        <span className="sort-icons">
          <span className="sort-icon" onClick={() => handleSort('location', 'asc')}>↑</span>
          <span className="sort-icon" onClick={() => handleSort('location', 'desc')}>↓</span>
        </span>
      </div>
          <div className="table-cell add-application" onClick={handleAddApplicationClick}>
            <span className="add-icon">+</span>
          </div>
        </div>
        {filterApplications().length > 0 && filterApplications().map((application, index) => (
          <div className="table-row" key={index} style={{ borderColor: chroma.random().hex() }}>
            <div className="table-cell">{application.name}</div>
            <div className="table-cell status-cell">
  <div
    className="status-background"
    style={{
      backgroundColor: getStatusDotColor(application.status), // dynamic color
    }}
  >
    {application.status}
  </div>
</div>

            <div className="table-cell">{application.date}</div>
            <div className="table-cell">{application.location}</div>
            <div className="table-cell">
              <FaEdit className="icon" onClick={() => handleOpenEditModal(application)} />
              <FaInfoCircle className="icon" onClick={() => handleOpenDescriptionModal(application)} />
              <FaTrash className="icon" onClick={() => handleOpenDeleteModal(application)} />
              <FaLink className="icon" onClick={() => handleCopy(application)}/>
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Add New Application</h2>
            <input type="text" name="name" placeholder="Company Name" value={newApplication.name} onChange={handleInputChange} required />
            {formErrors.name && <p className="error">{formErrors.name}</p>}
            <textarea name="description" placeholder="Job Description" value={newApplication.description} onChange={handleInputChange} required />
            {formErrors.description && <p className="error">{formErrors.description}</p>}
            <input type="date" name="date" value={newApplication.date} onChange={handleInputChange} required max={getTodayDate()} />
            {formErrors.date && <p className="error">{formErrors.date}</p>}
            <input type="url" name="link" placeholder="Job Posting Link" value={newApplication.link} onChange={handleInputChange} required />
            {formErrors.link && <p className="error">{formErrors.link}</p>}
            <input type="text" name="location" placeholder="Location" value={newApplication.location} onChange={handleInputChange} required />
            {formErrors.location && <p className="error">{formErrors.location}</p>}
            
            <div className="status-container">
              <label htmlFor="status" className="label-status">Status</label>
              <div className="custom-dropdown">
                <button className="dropdown-toggle">
                  <span className="status-dot" style={{ backgroundColor: getStatusDotColor(newApplication.status) }}></span>
                  {newApplication.status}
                </button>
                <div className="dropdown-menu">
                  {['pending', 'declined', '1st round', '2nd round', '3+ round', 'offer'].map((status) => (
                    <div
                      key={status}
                      className="dropdown-item"
                      onClick={() => setNewApplication({ ...newApplication, status })}
                    >
                      <span className="status-dot" style={{ backgroundColor: getStatusDotColor(status) }}></span>
                      {status}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <button className="submit-button" onClick={handleSubmit}>Submit</button>
            <button className="close-button" onClick={handleCloseModal}>x</button>
          </div>
        </div>
      )}

{showEditModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Edit Application</h2>
            <input type="text" name="name" placeholder="Company Name" value={newApplication.name} onChange={handleInputChange} required />
            {formErrors.name && <p className="error">{formErrors.name}</p>}
            <textarea name="description" placeholder="Job Description" value={newApplication.description} onChange={handleInputChange} required />
            {formErrors.description && <p className="error">{formErrors.description}</p>}
            <input type="date" name="date" value={newApplication.date} onChange={handleInputChange} required  max={getTodayDate()} />
            {formErrors.date && <p className="error">{formErrors.date}</p>}
            <input type="url" name="link" placeholder="Job Posting Link" value={newApplication.link} onChange={handleInputChange} required />
            {formErrors.link && <p className="error">{formErrors.link}</p>}
            <input type="text" name="location" placeholder="Location" value={newApplication.location} onChange={handleInputChange} required />
            {formErrors.location && <p className="error">{formErrors.location}</p>}
            
            <div className="status-container">
              <label htmlFor="status" className="label-status">Status</label>
              <div className="custom-dropdown">
                <button className="dropdown-toggle">
                  <span className="status-dot" style={{ backgroundColor: getStatusDotColor(newApplication.status) }}></span>
                  {newApplication.status}
                </button>
                <div className="dropdown-menu">
                  {['pending', 'declined', '1st round', '2nd round', '3+ round', 'offer'].map((status) => (
                    <div
                      key={status}
                      className="dropdown-item"
                      onClick={() => setNewApplication({ ...newApplication, status })}
                    >
                      <span className="status-dot" style={{ backgroundColor: getStatusDotColor(status) }}></span>
                      {status}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <button className="submit-button" onClick={handleEditSubmit}>Submit</button>
            <button className="close-button" onClick={handleCloseEditModal}>x</button>
          </div>
        </div>
      )}

      {showDescriptionModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>{selectedApplication?.name}</h2>
            <p>{selectedApplication?.description}</p>
            <button className="close-button" onClick={handleCloseDescriptionModal}>x</button>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Are you sure you want to delete this application?</h2>
            <button className="delete-button" onClick={handleDelete}>Delete</button>
            <button className="close-button" onClick={handleCloseDeleteModal}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
