import React, { useState } from 'react';
import './Login.css';
import logo from '../pic/todo.jpeg';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setError("");
  };

  const handleSubmit = () => {
    if (!formData.username || !formData.password) {
      setError("Both fields are required.");
      return;
    }

    axios.post('https://check-backend.xyz/auth/login', formData)
      .then((response) => {
        const token = response.data.token;
        const userId = response.data.userId;
        Cookies.set('token', token, { expires: 1 });
        Cookies.set('userid', userId, { expires: 1 });
        navigate('/');
      })
      .catch((error) => {
        console.error('Error:', error);
        setError("Invalid username or password.");
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className='Login'>
      <div className="mot">
        <Link to="/"><img src={logo} alt="Logo" /></Link>
      </div>
      <h1 className='Login-h1'>Login</h1>
      <div className='log-box'>
        {error && <p className="error-message">{error}</p>}
        <div className="input-box">
          <input
            type="text"
            placeholder="Username"
            id="username"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            required
          />
        </div>
        <div className="input-box">
          <input
            type="password"
            placeholder="Password"
            id="password"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            required
          />
        </div>
        <button className='login-button' onClick={handleSubmit}>Login</button>
        <p className='n-link'>Don't have an account? <Link to="/register">Register</Link></p>
      </div>
    </div>
  );
};

export default Login;